<template>
  <v-main>
    <div class="main-content mt-0">
  <Card class="card-custom br-0">
    <template #title>
      <v-tabs
      v-model="selectedTab"
      class="main-tabs"
      background-color="var(--white)"
  >
    <v-tab v-if="!isAgent" to="/authentications/admin">
      {{$t('admins')}}
    </v-tab>
    <v-tab to="/authentications/agent">
      {{$t('agents')}}
    </v-tab>
  </v-tabs>
    </template>
    <template #content>
     <router-view></router-view>
    </template>
  </Card>
</div>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
  export default {
    name: "Authentications",
    components:{
    },
    computed: {
      ...mapGetters({
        tokenUser: 'tokenUser',
      }),
      isAgent() {
        return this.tokenUser === "agent";
      }
    },
  created() {
},
    data() {
      return {
        selectedTab: 0,
      }
    },
    beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'authentications')
    });
    },
    watch:{
    },
    async mounted() {
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
.filtersCard {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: 55px;
  border-radius: 0!important;
  @media screen  and (max-width: 960px), (max-height: 500px) and (orientation: landscape) {
    position: static;
  }
}
.input-menu{
  color: rgba(0,0,0,.87);
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 8px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
  align-items: center;
  position: relative;
  &__menu{
    background-color: var(--white);
    box-shadow: none;
    padding: 10px;
    z-index: 3;
    position: relative;
    width: 100%;
    .v-input{
      margin-top: 8px;
      &:first-child{
        margin-top: 0px;
      }
    }
  }
}
</style>
